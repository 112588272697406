/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #0e8cf4;
    --color-secondary: #95ca44;
    --primary-light: #f7f8f9;
  }
}

@layer components {
  .form {
    @apply m-2 grid grid-cols-1 gap-4;
  }

  .form__group {
    @apply py-2;
  }

  .form__input {
    @apply my-1 block w-full rounded-md border-gray-300  placeholder-gray-400 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
  }

  .form__input--checkbox {
    @apply py-1 px-2 text-primary focus:outline-none;
  }

  .form__input--error {
    @apply border border-red-500 focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50;
  }

  .form__error {
    @apply text-sm text-red-500;
  }

  .form__label {
    @apply text-gray-700 py-1 font-bold;
  }

  .form__label--secondary {
    @apply font-normal text-sm;
  }

  .form__password--reveal {
    @apply flex items-center justify-center py-2 px-2 border border-gray-300 bg-gray-200 rounded-r-md cursor-pointer;
  }

  .btn {
    @apply px-4 py-2 whitespace-nowrap rounded-full text-sm capitalize shadow-md disabled:opacity-50 outline-none hover:shadow-xl focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150;
  }

  .btn__dashboard-form {
    @apply min-w-min w-20 md:w-36;
  }

  .btn--solid {
    @apply bg-primary text-white border border-primary;
  }

  .btn:disabled {
    @apply cursor-not-allowed;
  }

  .btn--outlined {
    @apply bg-white text-primary border border-primary hover:bg-primary hover:text-white;
  }

  .btn--delete {
    @apply bg-white text-red-600 border border-red-600 hover:bg-red-600 hover:text-white;
  }

  .btn__pink--solid {
    @apply bg-pink-450 text-white rounded;
  }

  .btn__pink--outline {
    @apply bg-white text-pink-450 border border-pink-450 rounded;
  }

  .icon__container {
    @apply p-2 rounded-lg bg-blue-500 text-white;
  }

  .headline {
    @apply text-xl font-normal text-gray-500;
  }

  .sidebar::-webkit-scrollbar {
    @apply w-2;
  }

  .sidebar::-webkit-scrollbar-track {
    @apply bg-primary-light rounded-md;
  }

  .sidebar::-webkit-scrollbar-thumb {
    @apply bg-primary rounded-md;
  }

  .sidebar::-webkit-scrollbar-thumb:hover {
    @apply bg-primary;
  }
}

.loader--inc {
  animation: increase 2s infinite;
}
.loader--dec {
  animation: decrease 2s 0.5s infinite;
}

.sidebar__curve {
  border-top-right-radius: 3rem;
}

.home__bg {
  background-color: #f7f8f9;
}

.public__page--bg {
  background-image: url('https://testimonials.imgix.net/pageRecord/RecordingPageBG.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-attachment: fixed;
  height: 100%;
}

.ba-videorecorder-chooser-container {
  background-color: #fdf2f8 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no__scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no__scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* BEGIN SWITCH BUTTON */
.dot {
  width: 0.75rem;
  height: 0.75rem;
  top: 0.1rem;
  left: 1.2px;
}

.switch-button:checked ~ .dot {
  transform: translateX(100%);
}

.switch-button:checked ~ .dot--container {
  background-color: #0e8cf4;
}
/* END SWITCH BUTTON */

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

#locating-your-review-loader {
  -webkit-animation: backandforth 2s ease 0s;
  -webkit-animation-iteration-count: infinite;
  animation: backandforth 3s ease 0s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes backandforth {
  0% {
    left: 0;
  }
  50% {
    left: 45%;
  }
  100% {
    left: 0;
  }
}

@keyframes backandforth {
  0% {
    left: 0;
  }
  50% {
    left: 45%;
  }
  100% {
    left: 0;
  }
}
